
import { IResponseGetArchives } from "@/@core/services/interfaces/geral/archive/IArchiveService";
import { AvButton } from "@/components";
import ENUMS from "@/enums";
// Services
import { useArchive } from "@/services";
import { convertDateTimestampToString } from "@/services/utils";
// Interfaces
import { INotification } from "@/store/firebase/firebaseState";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BAvatar,
  BBadge,
  BImg,
  BMedia,
  BNavItemDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { Timestamp } from "firebase/firestore";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Component, Vue } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import { RouterLink } from "vue-router";

@Component({
  name: "NotificationDropdown",
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    RouterLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar,
    AvButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class NotificationDropdown extends Vue {
  $ENUMS = ENUMS;
  perfectScrollbarSettings: {
    maxScrollbarLength: number;
    wheelPropagation: boolean;
  } = {
    maxScrollbarLength: 60,
    wheelPropagation: false,
  };
  renderComponent = false;

  // Computeds
  get notifications(): INotification[] {
    return this.$store.getters["firebase/notifications"];
  }

  get allMessagesRead(): boolean {
    const indexUnreadNotification = this.notifications.findIndex(
      (it) => !it.Read
    );
    return indexUnreadNotification == -1;
  }

  get newMessages(): string {
    const maxBadgeNumber = 99;
    const arrayLength = this.notifications.filter((it) => !it.Read).length;
    return arrayLength > maxBadgeNumber
      ? `${maxBadgeNumber}+`
      : "" + arrayLength;
  }

  // Methods
  resolverType(notification: INotification): string {
    if (notification.Type == "NotificacaoDashboard")
      return "Dashboard Atualizado";
    if (notification.Type == "NotificacaoValidacaoMarca")
      return "Validação da Marca";
    if (notification.Type == "NotificacaoCreateUserFinish")
      return "Usuário Criado com Sucesso";
    return notification.Message;
  }

  updateAllNotifications(read: boolean) {
    this.notifications.forEach((it) => {
      this.updateNotification(it, read);
    });
  }

  updateNotification(notification: INotification, read: boolean) {
    const n = {
      ...notification,
      Read: read,
    };
    this.$store.dispatch("firebase/updateNotification", n);
  }

  deleteNotification(notificationId: string) {
    this.$store.dispatch("firebase/deleteNotification", notificationId);
  }

  async downloadFile(item: INotification) {
    if (item.FileId) {
      item.Downloading = true;
      const path: string | null = await useArchive
        .requestGetArchives({
          MetaDados: "ValidacaoMarca",
        })
        .then((response: IResponseGetArchives): string | null => {
          const archive = response.data.data.find((it) => it.id == item.FileId);

          if (archive) {
            return archive.caminho;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Arquivo não encontrado",
                text: "O arquivo desejado não foi localizado, tente gerar um novo arquivo",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });

            return null;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao consultar arquivo",
              text: "Não foi possível consultar o arquivo solicitado",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          return null;
        });

      if (path) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = path;

        document.body.appendChild(a);
        a.click();
      }

      item.Downloading = false;
    }
  }

  getDateNotification(date: Timestamp): string {
    return convertDateTimestampToString(date);
  }
}
